<template>
  <div class="manual_resulting">
    <SportSidebar
      title="Sports"
      :selected-sport-id="selectedSportId"
      @update:selected-sport-id="updateSelectedSportId"
    />

    <div
      :class="[
        'manual_resulting__main',
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <div class="manual_resulting__header">
        <Heading>
          Manual resulting
        </Heading>
        <div
          class="manual_resulting-header-filters-wrapper"
        >
          <div
            class="header-filter-wrapper"
            ref="timeFilter"
          >
            <div
              class="selected-filter"
              @click="() => { timeDropdownOpened = !timeDropdownOpened }"
            >
              {{ selectedTimeOption.label }}
              <Icon
                :name="'chevron-down'"
                v-if="!timeDropdownOpened"
              />
              <Icon
                :name="'chevron-up'"
                v-else
              />
            </div>
            <div
              class="filter-dropdown"
              v-if="timeDropdownOpened"
            >
              <div
                :class="['dropdown-option time', { 'selected': option.label === selectedTimeOption.label }]"
                v-for="(option, index) in timeOptions"
                :key="index"
                @click="selectTimeOption(option)"
              >
                {{ option.label }}
              </div>
            </div>
          </div>
          <div class="manual_resulting-header-filters-search">
            <TextInput
              :model-value="searchValue"
              :placeholder="'Search events'"
              :icon="'search'"
              @update:modelValue="onSearch"
            />
          </div>
        </div>
      </div>

      <div
        ref="scrollerElement"
        class="manual_resulting__page"
      >
        <div class="manual_resulting__page__header">
          <div class="header-cell">
            Game
          </div>
          <div class="header-cell">
            Unresulted
          </div>
          <div class="header-cell">
            Competition
          </div>
          <div class="header-cell">
            Date & Time
          </div>
          <div class="header-cell">
            Status
          </div>
        </div>
        <div
          class="manual_resulting__page__body-row"
          v-for="event in events"
          :key="event.eventId"
          @click="openEventMarket(event)"
        >
          <div class="body-cell">
            <Icon
              v-if="event.eventType === 'REPLAY'"
              name="repeat-fourth"
              class="repeat-icon"
            />
            <div
              v-if="event.eventType === 'TEST'"
              title="Test Event"
              class="test-indicator"
            >
              (T)
            </div>
            <span>{{ getEventName(event) }}</span>
          </div>
          <div class="body-cell">
            {{ getUnresultedMarketsCount(event) }}
          </div>
          <div class="body-cell">
            {{ event.competition?.competitionName }}
          </div>
          <div class="body-cell">
            {{ formatDate(event.startsAt) }}
            <span class="time-cell">
              &bull;
              {{ formatTime(event.startsAt) }}
            </span>
          </div>
          <div class="body-cell">
            <EventTableStatusCell :event="event" />
          </div>
        </div>
        <div
          v-if="!events.length"
          class="empty-state-wrapper"
        >
          <EmptyState
            class="empty-state"
            :message="'No events available'"
          />
        </div>
      </div>
    </div>
    <ManualResultingEventMarkets
      v-if="selectedEvent"
      :selected-event="selectedEvent"
      @onClose="clearSelectedEvent"
    />
  </div>
</template>

<script>
import {
  ref, computed, watch, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import {
  cloneDeep, debounce, join, reverse, split, filter,
} from 'lodash';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import ManualResultingEventMarkets from './ManualResultingEventMarkets';
import EventTableStatusCell from '@/components/events/EventTableCell/EventTableStatusCell';
import Icon from '@/components/common/Icon';
import TextInput from '@/components/common/TextInput';
import EmptyState from '@/components/common/EmptyState';

export default {
  components: {
    Heading,
    SportSidebar,
    ManualResultingEventMarkets,
    Icon,
    EventTableStatusCell,
    TextInput,
    EmptyState,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const scrollerElement = ref(null);
    const operator = computed(() => route.query.client || store.getters.operator);

    const timeOptions = [
      {
        label: 'Today',
        timeValue: 0,
      },
      {
        label: 'Yesterday',
        timeValue: -1,
      },
      {
        label: 'Two days ago',
        timeValue: -2,
      },
      {
        label: 'Last seven days',
        timeValue: -7,
      },
    ];

    const getEventName = (event) => (event.isUsaView ? join(reverse(split(event.eventName, ' v ')), ' @ ') : event.eventName);
    const getUnresultedMarketsCount = (event) => {
      if (!event.eventMarketsResultingStatusesByEventId?.nodes?.length) return '-';

      const filteredMarketsResultingStatuses = filter(event.eventMarketsResultingStatusesByEventId.nodes, ({ operatorId }) => operatorId === operator.value);

      if (!filteredMarketsResultingStatuses.length) return '-';
      return filteredMarketsResultingStatuses[0].notResultedMarketCount;
    };

    const selectedSportId = ref('');

    const timeDropdownOpened = ref(false);
    const timeFilter = ref(false);

    const selectedTimeOption = ref(timeOptions[0]);

    const searchValue = ref('');

    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);

    const loadManualResultingEvents = () => {
      store.dispatch('loadManualResultingEvents',
        {
          timeValue: selectedTimeOption.value.timeValue,
          selectedSportId: selectedSportId.value,
          searchValue: searchValue.value,
        });
    };

    const debounceLoadResultingEvents = debounce(() => {
      loadManualResultingEvents();
    }, 500);

    const onSearch = (value) => {
      searchValue.value = value;
      debounceLoadResultingEvents();
    };

    const updateSelectedSportId = (sportId) => {
      selectedSportId.value = sportId;
      loadManualResultingEvents();
    };

    const selectTimeOption = (option) => {
      if (option.label === selectedTimeOption.value.label) return;
      selectedTimeOption.value = option;
      timeDropdownOpened.value = false;
      loadManualResultingEvents();
    };

    loadManualResultingEvents();

    const events = computed(() => store.getters.manualResultingEvents);

    const formatDate = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'MMM d');
    const formatTime = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'hh:mm a');

    const selectedEventId = computed(() => route.query.event);
    const selectedEvent = computed(() => store.getters.manualResultingEvent);

    const clearSelectedEvent = () => {
      const query = cloneDeep(route.query);
      delete query.event;
      router.replace({ ...route, query });
    };

    const openEventMarket = (event) => {
      store.dispatch('setManualResultingEvent', event);
      const query = cloneDeep(route.query);
      query.event = event.eventId;
      router.replace({ ...route, query });
    };

    onClickOutside(timeFilter, () => { timeDropdownOpened.value = false; });

    watch(
      selectedEventId,
      (newEventId) => {
        if (!newEventId) {
          store.dispatch('setManualResultingEvent', null);
          return;
        }
        store.dispatch('loadManualResultingEvent', newEventId);
      },
      { immediate: true },
    );

    onUnmounted(() => {
      store.dispatch('clearManualResultingEvents');
    });

    return {
      selectedEventId,
      scrollerElement,
      selectedSportId,
      updateSelectedSportId,
      selectedTimeOption,
      selectTimeOption,
      timeOptions,
      timeDropdownOpened,
      timeFilter,
      events,
      formatDate,
      formatTime,
      openEventMarket,
      selectedEvent,
      clearSelectedEvent,
      searchValue,
      sidebarCollapsed,
      onSearch,
      getEventName,
      getUnresultedMarketsCount,
    };
  },
};
</script>

<style lang="scss">
.manual_resulting {
  width: 100%;

  .manual_resulting__main {
    flex: 1;
    width: calc(100% - #{$sidebarWidth});
    height: 100%;

    &.full-width {
      width: 100%;
    }

    &.sidebar-collapsed {
      width: calc(100% - #{$sidebarWidthCollapsed});
    }

    .footer {
      position: fixed;
      bottom: 0;
      width: calc(100% - #{$sidebarWidth});
      z-index: $mappingTableFooter;

      &.full-width {
        width: 100%;
      }
    }
  }

  .manual_resulting__header {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .manual_resulting-header-filters-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      margin-top: 8px;
      justify-content: space-between;

      .header-filter-wrapper {
        height: 33px;
        position: relative;
        display: flex;
        align-items: center;

        .selected-filter {
          border: 1px solid #DDDDDD;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 0 12px;
          display: flex;
          min-width: 165px;
          align-items: center;
          height: 100%;
          justify-content: space-between;
          cursor: pointer;

          .icon {
            margin-left: 4px;

            svg {
              stroke: #191414;
            }
          }

          img {
            height: 14px;
            width: 14px;
            margin-right: 4px;
          }
        }

        .filter-dropdown {
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          background-color: #fff;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          z-index: $dropdownMenuZIndex;

          .dropdown-option {
            height: 33px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;
            cursor: pointer;

            &.time {
              &.selected {
                background-color: rgba(0, 0, 0, 0.02);
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }
            }
          }
        }
      }

      .manual_resulting-header-filters-search {
        height: 33px;
        width: 336px;

        .text-field {
          height: 33px;

          .text-field-clear {
            width: 33px;
            height: 33px;
          }
        }
      }
    }
  }

  .manual_resulting__page {
    width: 100%;
    height: calc(100% - 100px - #{$footerPaginationHeight});
    overflow: auto;
    padding: 0 24px 24px;

    .manual_resulting__page__header {
      display: flex;
      width: 100%;
      height: 44px;
      background: #FAFAFA;

      .header-cell {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 8px;
        color: #6D6D6D;
        flex-basis: 20%;
      }
    }

    .manual_resulting__page__body-row {
      display: flex;
      width: 100%;
      height: 44px;
      box-shadow: inset 0px -1px 0px #DDDDDD;
      cursor: pointer;

      .body-cell {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 8px;
        color: #191414;
        flex-basis: 20%;
        max-width: 20%;

        span {
          max-width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time-cell {
          color: #A9A9A9;
          margin-left: 4px;
        }

        .repeat-icon, .test-indicator {
          margin-right: 4px;
        }
      }
    }

    .empty-state-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
      width: 100%;

      .empty-state {
        width: 400px;
      }
    }
  }
}

</style>
